import classes from "./ReviewsList.module.css";
import Card from "../UI/Card/Card";

import { BsLaptop } from "react-icons/bs";
import { AiTwotoneStar } from "react-icons/ai";
import { GiCoffeeBeans, GiMilkCarton } from "react-icons/gi";
import { BsFillHeartFill } from "react-icons/bs";
import {
  Key,
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
} from "react";

import { useNavigate, useOutletContext } from "react-router-dom";

const ReviewsList = (props: any) => {
  const navigate = useNavigate();

  const HandleGoToReviewDetail = () => {
    navigate("/review/candlekidcoffee");
  };

  const [filterCriteria]: any = useOutletContext();
  const fullReviewList = props.listToDisplay;

  const filterList = () => {
    return fullReviewList.filter((item: any) =>
      item.title.toLowerCase().includes(filterCriteria.toLowerCase())
    );
  };

  let listToBeDisplayed =
    filterCriteria?.length > 0 ? filterList() : fullReviewList;

  console.log("list to display ", filterCriteria, listToBeDisplayed);

  return (
    <div className={classes.reviewlist}>
      <ul className={classes.list}>
        {listToBeDisplayed.map(
          (review: {
            laptopPolicy: ReactNode;
            coffeeType: ReactNode;
            alternativeMilk: ReactNode;
            rating: ReactNode;
            details: any;
            id: Key | null | undefined;
            isFavorite: any;
            img: string | undefined;
            title:
              | string
              | number
              | boolean
              | ReactElement<any, string | JSXElementConstructor<any>>
              | Iterable<ReactNode>
              | null
              | undefined;
            laptop:
              | string
              | number
              | boolean
              | ReactElement<any, string | JSXElementConstructor<any>>
              | Iterable<ReactNode>
              | ReactPortal
              | null
              | undefined;
          }) => (
            <Card
              className="test"
              key={review.id}
              onClick={HandleGoToReviewDetail}
            >
              {review.isFavorite && (
                <div className={classes.favorite}>
                  <span className={classes.icon}>
                    <BsFillHeartFill />
                  </span>
                  <div className={classes.favoritetext}>
                    La crème de la crème
                  </div>
                </div>
              )}
              <img className={classes.img} src={review.img} />
              <div className={classes["card-content"]}>
                <h1 className={classes.contenttitle}>
                  <div>{review.title}</div>
                  <div className={classes.rating}>
                    <div className={classes.contentratingicon}>
                      <AiTwotoneStar />
                    </div>
                    <div className={classes.contentrating}>
                      {" "}
                      {review.rating}
                    </div>
                  </div>
                </h1>
                <a className={classes.contentlocation}>Paris 20</a>
                <ul className={classes.details}>
                  <li className={classes.detailsitem}>
                    <BsLaptop /> {review.laptopPolicy}
                  </li>
                  <li className={classes.detailsitem}>
                    <GiCoffeeBeans /> {review.coffeeType}
                  </li>
                  <li className={classes.detailsitem}>
                    <GiMilkCarton /> {review.alternativeMilk}
                  </li>
                </ul>
              </div>
            </Card>
          )
        )}
      </ul>
    </div>
  );
};

export default ReviewsList;
