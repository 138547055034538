import { MouseEventHandler, ReactNode } from "react";
import classes from "./Card.module.css";
import { AnimatePresence, motion } from "framer-motion";

const Card: React.FC<{
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
  className: string;
  children: ReactNode;
}> = (props) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.7 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.9 }}
        className={`${classes.card} ${props.className}`}
        onClick={props.onClick}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
};

export default Card;
