import classes from "./Input.module.css";

const InputField = (props: any) => {
  const { label, type, name, handleChange, errorMessage, isValid, value } =
    props;

  return (
    <div>
      <label>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        className={classes.inputContainer}
      />
      {errorMessage && !isValid && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
  );
};

export default InputField;
