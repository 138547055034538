import Navigation from "./Navigation";
import classes from "./MainHeader.module.css";
import logo from "../../assets/logo.png";

interface MainHeaderProps {
  onSearch: (value: string) => void;
}

const MainHeader: React.FC<MainHeaderProps> = ({ onSearch }) => {
  return (
    <header className={classes["main-header"]}>
      <img src={logo} className={classes["main-header-logo"]}></img>
      <Navigation onSearch={onSearch} />
    </header>
  );
};

export default MainHeader;
