import classes from "./Navigation.module.css";
import { BsSearch } from "react-icons/bs";
import { BsSliders } from "react-icons/bs";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Contact from "../../pages/Contact";

interface MainHeaderProps {
  onSearch: (value: string) => void;
}

const Navigation: React.FC<MainHeaderProps> = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();

  const HandleGoToContact = () => {
    navigate("/contact");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    onSearch(value);
  };

  return (
    <nav className={classes.nav}>
      <ul>
        <li>
          <div className="search-container">
            <i>
              <BsSearch />
            </i>
            <input
              type="search"
              placeholder="✌️ Trouve ton futur QG ! ☕"
              value={searchValue}
              onChange={handleInputChange}
              className={classes["search-input"]}
            />
          </div>
        </li>
        <li>
          <motion.button className={classes.filter} whileHover={{ scale: 1.1 }}>
            <BsSliders />
            Filtres
          </motion.button>
        </li>
        <li>
          <motion.button
            className={classes.filter}
            whileHover={{ scale: 1.1 }}
            onClick={HandleGoToContact}
          >
            Contact
          </motion.button>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
