import classes from "./ReviewDetails.module.css";
import img from "../../assets/candlekid.jpg";

const ReviewDetail = (props: any) => {
  return (
    <div className={classes.ReviewDetail}>
      <h1 className={classes["ReviewDetail__title"]}>Candle Kid Coffee</h1>
      <div className={classes["ReviewDetail__subtitle"]}>
        Ceci est un placeholder de test
      </div>
      <div className={classes["ReviewDetail__gallery"]}></div>
      <div>
        <h3> Notre avis </h3>
        <p>
          {" "}
          J'ai récemment eu le plaisir de découvrir ce nouveau lieu du 20e et je
          suis absolument ravie de l'expérience que j'y ai vécue. Dès que j'ai
          franchi la porte, l'arôme envoûtant du café fraîchement torréfié a
          éveillé mes sens. L'ambiance est tout simplement charmante, avec des
          œuvres d'art locales accrochées aux murs, créant une atmosphère
          chaleureuse et authentique. Les tables en bois rustique et les
          fauteuils confortables invitent à la détente, tandis que le personnel
          sympathique et attentionné ajoute une touche personnelle à
          l'expérience. Mais bien sûr, la vraie star ici, c'est le café. Mon
          latte était tout simplement divin, avec une créma veloutée et une
          mousse de lait parfaitement réalisée. Si vous êtes un amateur de café
          à la recherche d'un endroit spécial à Paris, ne cherchez pas plus
          loin.
        </p>
      </div>
      <div>
        <h3> Concernant la carte </h3>
      </div>
      <div>
        <h3> Concernant le lieu </h3>
      </div>
    </div>
  );
};

export default ReviewDetail;
