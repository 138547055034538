import { useParams } from "react-router-dom";
import ReviewDetail from "../components/Reviews/ReviewDetail";

const ReviewPage: React.FC = () => {
  const params = useParams();

  return <ReviewDetail name={params.reviewId} />;
};

export default ReviewPage;
