import classes from "./MainFooter.module.css";
import { BsInstagram } from "react-icons/bs";
import { FaSquareXTwitter, FaTiktok } from "react-icons/fa6";
import { motion } from "framer-motion";

function SocialListItem({ icon, text }: any) {
  return (
    <motion.li
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.9 }}
      className={classes.footersectionsocialitem}
    >
      {icon} {text}
    </motion.li>
  );
}

const MainFooter: React.FC = () => {
  return (
    <footer className={classes["main-footer"]}>
      <div className={classes.footersection}>
        <h3>A propos</h3>
        <p>
          Ma vie de pomme de terre a toujours été empreinte de curiosité pour
          l'arôme envoûtant du café. Née dans un champ fertile, j'ai rapidement
          développé une passion pour les grains de café fraîchement moulus. Ce
          site est fait pour vous partager ma passion.
        </p>
      </div>
      <div className={classes.footersection}>
        <h3>Me suivre</h3>
        <motion.ul className={classes.footersectionsocial}>
          <SocialListItem icon={<BsInstagram />} text="Instagram" />
          <SocialListItem icon={<FaSquareXTwitter />} text="Twitter/X" />
          <SocialListItem icon={<FaTiktok />} text="TikTok" />
        </motion.ul>
      </div>
      <div className={classes.footersection}>
        <h3>Mentions légales</h3>
        <p>Placeholder</p>
        <h3>Contact</h3>
        <p>Placeholder</p>
      </div>
    </footer>
  );
};

export default MainFooter;
