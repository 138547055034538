import * as yup from "yup";
import { useFormik } from "formik";
import classes from "./ContactForm.module.css";
import InputField from "../UI/Input/Input";
import profileImage from "../../assets/profile.jpeg";
import { motion } from "framer-motion";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  message: yup.string().required("Message is required"),
});

function ContactForm() {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Form submitted with data:", values);
    },
  });

  return (
    <div className={classes.container}>
      <form onSubmit={formik.handleSubmit} className={classes.contact}>
        <div className={classes.contactinput}>
          <label>Name:</label>
          <InputField
            type="text"
            name="name"
            handleChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name && (
            <div>{formik.errors.name}</div>
          )}
        </div>
        <div className={classes.contactinput}>
          <label>Email:</label>
          <InputField
            type="email"
            name="email"
            handleChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email && (
            <div>{formik.errors.email}</div>
          )}
        </div>
        <div className={classes.contactinputmessage}>
          <label>Message:</label>
          <InputField
            type="message"
            name="message"
            handleChange={formik.handleChange}
            value={formik.values.message}
          />
          {formik.errors.message && formik.touched.message && (
            <div>{formik.errors.message}</div>
          )}
        </div>
        <motion.button className={classes.button} whileHover={{ scale: 1.1 }}>
          Submit
        </motion.button>
      </form>
      <div className={classes.pictureContainer}>
        <img src={profileImage} alt="Me" className={classes.picture} />
      </div>
    </div>
  );
}

export default ContactForm;
