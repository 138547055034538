import { useState } from "react";
import MainHeader from "../components/MainHeader/MainHeader";

const ErrorPage: React.FC = () => {
  const [filterCriteria, setFilterCriteria] = useState("");

  const handleSearch = (value: any) => {
    setFilterCriteria(value);
  };

  return (
    <>
      <MainHeader onSearch={handleSearch} />
      <main>
        <h1> Oups une erreur s'est produite !</h1>
        <p> Could not find this page : 404 </p>
      </main>
    </>
  );
};

export default ErrorPage;
