import { Outlet } from "react-router-dom";

import MainHeader from "../MainHeader/MainHeader";
import MainFooter from "../MainFooter/MainFooter";
import { useState } from "react";

const GlobalLayout: React.FC = () => {
  const [filterCriteria, setFilterCriteria] = useState("");

  const handleSearch = (value: any) => {
    setFilterCriteria(value);
  };

  return (
    <>
      <MainHeader onSearch={handleSearch} />
      <main>
        <Outlet context={[filterCriteria]} />
      </main>
      <MainFooter />
    </>
  );
};

export default GlobalLayout;
