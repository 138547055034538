import { RouterProvider, createBrowserRouter } from "react-router-dom";

import HomePage, { fetchReviewDataLoader as reviewLoader } from "./pages/Home";
import ReviewPage from "./pages/Review";
import ErrorPage from "./pages/Error";

import GlobalLayout from "./components/Layout/GlobalLayout";
import Contact from "./pages/Contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <GlobalLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: reviewLoader,
      },
      {
        path: "/review/:reviewId",
        element: <ReviewPage />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
