import { useLoaderData, useOutletContext } from "react-router-dom";
import ReviewsList from "../components/Reviews/ReviewsList";
import GoogleMapReact from "google-map-react";
import Heart from "../components/UI/Heart/Heart";

interface MapProps {
  lat: number;
  lng: number;
}

export const fetchReviewDataLoader = async (props: any) => {
  const url = "https://coffee-gang-backend.vercel.app/reviews";
  try {
    const response = await fetch(url);
    const listToJson = await response.json();
    return listToJson;
  } catch (error) {
    console.log("error", error);
  }
};

const MapComponent: React.FC<MapProps> = ({ lat, lng }) => (
  <div style={{ height: "50%", width: "90%" }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyDMsBiZtNrcY67DX3CzTRUXZkI6XAuRI3Q" }}
      defaultCenter={{ lat: lat, lng: lng }}
      defaultZoom={11}
    >
      {/* You can add markers, polygons, etc. here */}
    </GoogleMapReact>
  </div>
);

const HomePage = (props: any) => {
  const coffeeList: any = useLoaderData();
  const mapCenter = { lat: 48.866667, lng: 2.333333 };

  return (
    <div className="homepage-container">
      <div className="coffees-list">
        <ReviewsList listToDisplay={coffeeList} />
      </div>
      <div className="map">
        <MapComponent lat={mapCenter.lat} lng={mapCenter.lng} />
      </div>
    </div>
  );
};

export default HomePage;
